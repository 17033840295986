import React, { useState, useEffect } from 'react';
import { Pane, Heading, Button, Spinner, toaster } from 'evergreen-ui';
import { useQuery, useMutation } from '@apollo/react-hooks';
import useInterval from '../../hooks/useInterval';
import RandomQuestion from './RandomQuestion';
import RatingQuestion from './RatingQuestion';
import USER_QUERY from '../../queries/user/user';
import EVALUATE_ADD from '../../queries/evaluate/add';

// get random question
// show question
// onAnswer - show rating
// create new analitycs record in DB
// repeat

export default function Evaluate() {
  const [count, setCount] = useState(0);
  const [userId, setUserId] = useState(null);
  const [answerIndex, setAnswerIndex] = useState(null);
  const [questionItem, setQuestionItem] = useState({});
  const [answerTime, setAnswerTime] = useState(null);

  const { data: userData } = useQuery(USER_QUERY);
  const [addEvaluate, { loading }] = useMutation(EVALUATE_ADD);

  useEffect(() => {
    if (userData?.me) {
      setUserId(userData.me.id);
    }
  }, [userData]);

  const handlerRating = async rate => {
    const isCorrectAnswer = answerIndex === questionItem.correctAnswer;

    const evaluateData = {
      questionId: questionItem.id,
      evaluatorId: userId,
      isCorrectAnswer,
      answerResponseTime: answerTime,
      isBlitz: questionItem.isBlitz,
      questionQuality: rate,
    };

    const { data } = await addEvaluate({ variables: evaluateData });
    if (data.createEvaluateAnalytic) {
      toaster.success('Question successfully rated');
      setAnswerIndex(null);
      setQuestionItem({});
      setAnswerTime(null);
      setCount(count + 1);
    }
  };

  if (loading) return <Spinner marginX="auto" marginY={120} />;

  if (answerIndex !== null) {
    return <RatingQuestion onRating={handlerRating} />;
  }

  return (
    <>
      <RandomQuestion>
        {({ question }) => {
          const timeStart = Date.now();
          return (
            <Pane>
              <Heading size={700} className="heading">
                {question.questionTitle}
              </Heading>
              {question.answers.map((answer, index) => (
                <Button
                  key={index}
                  height={56}
                  onClick={() => {
                    const timeEnd = Date.now();
                    const seconds = (timeEnd - timeStart) / 1000;
                    setAnswerTime(seconds);
                    setQuestionItem(question);
                    setAnswerIndex(index);
                  }}
                  className="answer-button">
                  {answer}
                </Button>
              ))}
            </Pane>
          );
        }}
      </RandomQuestion>
    </>
  );
}
