import gql from 'graphql-tag';

const QUESTIONS_RANGE = gql`
  query QuestionsRange($limit: Int!, $start: Int!) {
    questions(limit: $limit, start: $start, sort: "updatedAt:desc") {
      id
      questionTitle
      correctAnswer
      answers
      isBlitz
    }
  }
`;

export default QUESTIONS_RANGE;
