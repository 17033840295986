import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Navbar from './components/NavBar';
import Login from './components/Login';
import AppQuestions from './components/questions/AppQuestions';
import AppEvaluate from './components/evaluate/AppEvaluate';
import AppUser from './components/User';
import AppHome from './components/home/AppHome';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/app.scss';

export default function App() {
  return (
    <>
      <Navbar />
      <div className="container">
        <Switch>
          <Route exact path="/" component={AppHome} />
          <Route path="/sign-in" component={Login} />
          <Route path="/profile" component={AppUser} />
          <Route path="/questions" component={AppQuestions} />
          <Route path="/evaluate" component={AppEvaluate} />
        </Switch>
      </div>
    </>
  );
}
