import gql from 'graphql-tag';

const TAGS_QUERY = gql`
  query QuestionTags {
    questionTags {
      id
      title
    }
  }
`;

export default TAGS_QUERY;
