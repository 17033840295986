import gql from 'graphql-tag';

const STATS_QUERY = gql`
  query Stats($id: ID!) {
    evaluateAnalytics(sort: "createdAt:asc", where: { evaluatorId: $id }) {
      id
      createdAt
    }
  }
`;

export default STATS_QUERY;
