import React, { useState, useEffect } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { useQuery } from '@apollo/react-hooks';
import QUESTIONS_COUNT from '../../../queries/questions/count';

const ON_PAGE = 100;

export default function AppPagination({ setVariables }) {
  // pagination
  const [pageNum, setPageNum] = useState(1);
  const [pageMaxNum, setPageMaxNum] = useState(null);
  const { data: questionsCount } = useQuery(QUESTIONS_COUNT);

  useEffect(() => {
    const variables = { start: (pageNum - 1) * 100, limit: ON_PAGE };
    setVariables(variables);
  }, []);

  useEffect(() => {
    if (questionsCount) {
      const cnt = questionsCount.questionsCount;
      const maxPage = Math.ceil(cnt / ON_PAGE);
      setPageMaxNum(maxPage);
    }
  }, [questionsCount]);

  const handlerPage = number => {
    const variables = { start: (number - 1) * 100, limit: ON_PAGE };
    setPageNum(number);
    setVariables(variables);
  };

  const items = new Array(pageMaxNum).fill('').map((_, num) => {
    const number = num + 1;
    return (
      <Pagination.Item
        key={number}
        active={number === pageNum}
        onClick={() => handlerPage(number)}>
        {number}
      </Pagination.Item>
    );
  });

  return <Pagination>{items}</Pagination>;
}
