import gql from 'graphql-tag';

const ADD_QUESTION = gql`
  mutation AddQuestion(
    $title: String!
    $imageUrl: String
    $answers: JSON!
    $correctAnswer: Int!
    $authorId: String!
    $tags: [ID]!
  ) {
    createQuestion(
      input: {
        data: {
          questionTitle: $title
          imageUrl: $imageUrl
          answers: $answers
          correctAnswer: $correctAnswer
          authorId: $authorId
          tags: $tags
        }
      }
    ) {
      question {
        id
        authorId
        questionTitle
        imageUrl
        answers
        correctAnswer
        tags {
          id
          title
        }
      }
    }
  }
`;

export default ADD_QUESTION;
