import React from 'react';
import { Switch, Route, useRouteMatch, Link } from 'react-router-dom';

import Show from './Show';
import List from './List';
import Add from './Add';
import Edit from './Edit';
import Statistics from './Statistics';

export default function ListQuestions() {
  const { path } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route exact path={path} component={List} />
        <Route path={`${path}/show/:id`} component={Show} />
        <Route path={`${path}/add`} component={Add} />
        <Route path={`${path}/edit/:id`} component={Edit} />
        <Route path={`${path}/statistics`} component={Statistics} />
      </Switch>
    </>
  );
}
