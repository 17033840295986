import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import STATS_QUERY from '../../../queries/evaluate/stats';
import { Table, Spinner } from 'evergreen-ui';

export default function StatTable({ id }) {
  const { data, loading } = useQuery(STATS_QUERY, {
    variables: { id },
  });

  if (loading) return <Spinner />;

  const dates = data && data.evaluateAnalytics ? data.evaluateAnalytics.map(q => ({
    date: q.createdAt,
    count: 1,
  })) : [];
  
  const datesByWeek = groupWeeks(dates);

  return (
    <Table>
      <Table.Head>
        <Table.TextHeaderCell>Start Week</Table.TextHeaderCell>
        <Table.TextHeaderCell>End Week</Table.TextHeaderCell>
        <Table.TextHeaderCell>Count of evaluate questions</Table.TextHeaderCell>
      </Table.Head>
      <Table.Body>
        {datesByWeek.map(week => (
          <Table.Row key={week.weekStart}>
            <Table.TextCell>{week.weekStart}</Table.TextCell>
            <Table.TextCell>{week.weekEnd}</Table.TextCell>
            <Table.TextCell isNumber>{week.count}</Table.TextCell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

function groupWeeks(data) {
  const getMonday = dateString => {
    const date = new Date(dateString);
    date.setDate(date.getDate() - ((date.getDay() + 6) % 7));
    return date.toISOString().slice(0, 10);
  };

  const getSunday = mondayString => {
    const monday = new Date(mondayString);
    monday.setDate(monday.getDate() + 6);
    return monday.toISOString().slice(0, 10);
  };

  const groupingData = data.reduce((obj, item) => {
    const weekStart = getMonday(item.date);
    const week = obj[weekStart] || { count: 0 };

    week.count += item.count;

    return { ...obj, [weekStart]: week };
  }, {});

  return Object.entries(groupingData).map(([weekStart, res]) => ({
    weekStart,
    weekEnd: getSunday(weekStart),
    count: res.count,
  }));
}
