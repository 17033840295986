import gql from 'graphql-tag';

const STATS_QUERY = gql`
  query Stats($id: ID!) {
    questions(sort: "updatedAt:desc", where: { authorId: $id }) {
      id
      authorId
      createdAt
    }
  }
`;

export default STATS_QUERY;
