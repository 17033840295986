import React, { useState, useEffect } from 'react';

import { SelectMenu, Button } from 'evergreen-ui';

export default function Multiselect({ tags = [], onSelect, title = 'Edit Tags...' }) {
  const [options, setOptions] = useState([]);  
  const [allSelected, setAllSelected] = useState([]);
  const [selectedNames, setSelectedNames] = useState('Please select tags');

  useEffect(() => {
    const options = tags.map(tag => ({ label: tag.title, value: tag.title }));
    setOptions(options);

    const selected = tags.filter(t => t.selected);
    const selectedTitles = selected.map(item => item.title);
    setAllSelected(selectedTitles);
    setSelectedNames(selectedTitles.join(', '));
  }, [tags]);

  if (!options.length) {
    return <Button isLoading={true} />;
  }

  return (
    <>
      <SelectMenu
        isMultiSelect
        title="Select multiple names"
        options={options}
        selected={allSelected}
        onSelect={item => {
          const selected = [...allSelected, item.value];
          const selectedItems = selected;
          const selectedItemsLength = selectedItems.length;
          let selectedNames = '';
          if (selectedItemsLength === 0) {
            selectedNames = '';
          } else if (selectedItemsLength === 1) {
            selectedNames = selectedItems.toString();
          } else if (selectedItemsLength > 1) {
            selectedNames = selectedItems.join(', ');
            // selectedNames = selectedItemsLength.toString() + ' selected...';
          }

          setAllSelected(selected);
          onSelect(selected);
          setSelectedNames(selectedNames);
        }}
        onDeselect={item => {
          const deselectedItemIndex = allSelected.indexOf(item.value);
          const selectedItems = allSelected.filter(
            (_item, i) => i !== deselectedItemIndex,
          );
          const selectedItemsLength = selectedItems.length;
          let selectedNames = '';
          if (selectedItemsLength === 0) {
            selectedNames = '';
          } else if (selectedItemsLength === 1) {
            selectedNames = selectedItems.toString();
          } else if (selectedItemsLength > 1) {
            selectedNames = selectedItems.join(', ');
            // selectedNames = selectedItemsLength.toString() + ' selected...';
          }

          setAllSelected(selectedItems);
          onSelect(selectedItems);
          setSelectedNames(selectedNames);
        }}>
        <Button>{selectedNames || title}</Button>
      </SelectMenu>
    </>
  );
}
