import gql from 'graphql-tag';

const UPDATE_QUESTION_TAGS = gql`
  mutation UpdateQuestion($id: ID!, $tags: [ID]!) {
    updateQuestion(input: { where: { id: $id }, data: { tags: $tags } }) {
      question {
        id
        questionTitle
        answers
        correctAnswer
        tags {
          id
          title
        }
      }
    }
  }
`;

export default UPDATE_QUESTION_TAGS;
