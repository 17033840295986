import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner, Heading, toaster } from 'evergreen-ui';

import { useMutation, useQuery } from '@apollo/react-hooks';

import UPDATE_QUESTION from '../../queries/questions/update';
import GET_QUESTION from '../../queries/questions/question';
import GET_TAGS from '../../queries/tags/get';

import QuestionFields from './ui/questionFields';

export default function () {
  const { id } = useParams();

  const { loading: questionLoading, data } = useQuery(GET_QUESTION, {
    variables: { id },
  });
  const [updateQuestion, { loading }] = useMutation(UPDATE_QUESTION);
  const [title, setTitle] = useState('');
  const [imageUrl, setImageUrl] = useState('')
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [answers, setAnswers] = useState(['', '', '', '']);

  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const { data: tagsData } = useQuery(GET_TAGS);

  useEffect(() => {}, [data]);

  useEffect(() => {
    if (tagsData) {
      setTags(tagsData.questionTags);
    }

    if (data) {
      const { questionTitle,imageUrl, answers, correctAnswer } = data.question;
      setTitle(questionTitle);
      setImageUrl(imageUrl);
      setAnswers(answers);
      setCorrectAnswer(correctAnswer);
    }

    if (tagsData && data) {
      const questionTags = data.question.tags;
      setTags(prev => {
        return prev.map(tag => {
          const index = questionTags.findIndex(q => q.id === tag.id);
          const selected = index !== -1;
          return { ...tag, selected };
        });
      });
    }
  }, [tagsData, data]);

  const handlerSubmit = async e => {
    e.preventDefault();

    const selectedTagsIds = selectedTags.map(
      selectedTitle => tags.find(item => item.title === selectedTitle).id,
    );

    const questionData = {
      id,
      title,
      imageUrl,
      answers,
      correctAnswer,
      tags: selectedTagsIds,
    };
    const { data } = await updateQuestion({ variables: questionData });
    if (data) {
      toaster.success('Question successfully edit');
    }
  };

  const setAnswer = (title, index) => {
    setAnswers(prev => {
      const data = [...prev];
      data[index] = title;
      return data;
    });
  };

  const isSubmitAllow = [...answers, title, correctAnswer].every(
    item => item !== null && item !== '',
  );

  if (questionLoading) return <Spinner marginX="auto" marginY={120} />;

  return (
    <>
      <Heading size={700} className="heading">
        Edit question
      </Heading>
      <QuestionFields
        answers={answers}
        correctAnswer={correctAnswer}
        handlerSubmit={handlerSubmit}
        title={title}
        setTitle={setTitle}
        setAnswer={setAnswer}
        setImageUrl={setImageUrl}
        imageUrl={imageUrl}
        setCorrectAnswer={setCorrectAnswer}
        isSubmitAllow={isSubmitAllow}
        loading={loading}
        tags={tags}
        setSelectedTags={setSelectedTags}
      />
    </>
  );
}
