import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import STATS_QUERY from '../../queries/evaluate/stats';
import USER_QUERY from '../../queries/user/user';
import { Pane, Spinner } from 'evergreen-ui';
import StatTable from './ui/statTable';

export default function Statistics() {
  const { data, loading } = useQuery(USER_QUERY);
  const userId = data?.me?.id;

  if (loading) return <Spinner />;

  return <Pane marginTop={20}>{userId && <StatTable id={userId} />}</Pane>;
}
