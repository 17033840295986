import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import Query from '../Query';

import SIGN_IN_MUTATION from '../../queries/login/signIn';

export default function AppLogin() {
  const [signIn] = useMutation(SIGN_IN_MUTATION);
  const [email, setEmail] = useState('test@gmail.com');
  const [password, setPassword] = useState('Psw123456');

  const history = useHistory();

  // console.log(signIn);

  const handlerForm = async e => {
    e.preventDefault();

    const { data } = await signIn({ variables: { email, password } });
    localStorage.setItem('token', data.login.jwt);

    // history.push(`/`);
    window.location.href = '/';
  };

  return (
    <>
      <Form onSubmit={handlerForm}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            onChange={e => setEmail(e.target.value)}
            type="email"
            placeholder="Enter email"
            value={email}
          />
          <Form.Text className="text-muted">
            We'll never share your email with anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            onChange={e => setPassword(e.target.value)}
            type="password"
            placeholder="Password"
            value={password}
          />
        </Form.Group>
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check type="checkbox" label="Check me out" />
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </>
  );
}
