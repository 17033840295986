import gql from 'graphql-tag';

const DELETE_QUESTION = gql`
  mutation DeleteQuestion($id: ID!) {
    deleteQuestion(input: { where: { id: $id } }) {
      question {
        id
        questionTitle
      }
    }
  }
`;

export default DELETE_QUESTION;
