import gql from 'graphql-tag';

const USER_QUERY = gql`
  query {
    me {
      id
      username
      email
      confirmed
      role {
        name
        description
        type
      }
    }
  }
`;

export default USER_QUERY;
