import React from 'react';
import { Button } from 'evergreen-ui';

import Query from '../Query';
import USER_QUERY from '../../queries/user/user';

export default function AppNavbar() {
  return (
    <>
      <Query query={USER_QUERY} id={null}>
        {({ data: { me } }) => {
          return (
            <>
              <div>{me.id}</div>
              <div>{me.username}</div>
              <div>{me.email}</div>
              <div>{String(me.confirmed)}</div>
            </>
          );
        }}
      </Query>
    </>
  );
}
