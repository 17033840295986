import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Pane, TextInputField, Button, Radio, Heading } from 'evergreen-ui';

import { useMutation, useQuery } from '@apollo/react-hooks';

import ADD_QUESTION from '../../queries/questions/add';
import ALL_QUESTIONS_QUERY from '../../queries/questions/all';
import USER_QUERY from '../../queries/user/user';
import GET_TAGS from '../../queries/tags/get';

import Multiselect from './ui/multiselect';

export default function () {
  const history = useHistory();

  const [userId, setUserId] = useState(null);
  const [title, setTitle] = useState('test title');
  const [imageUrl, setImageUrl] = useState();
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [answers, setAnswers] = useState([
    'variant 1',
    'variant 2',
    'variant 3',
    'variant 4',
  ]);

  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const [addQuestion, { loading }] = useMutation(ADD_QUESTION);

  const { data: userData } = useQuery(USER_QUERY);
  const { data: tagsData } = useQuery(GET_TAGS);

  useEffect(() => {
    if (userData?.me) {
      setUserId(userData.me.id);
    }
  }, [userData]);

  useEffect(() => {
    if (tagsData) {
      setTags(tagsData.questionTags);
    }
  }, [tagsData]);

  const handlerSubmit = async e => {
    e.preventDefault();

    const selectedTagsIds = selectedTags.map(
      selectedTitle => tags.find(item => item.title === selectedTitle).id,
    );

    const questionData = {
      title,
      imageUrl,
      answers,
      correctAnswer,
      authorId: userId,
      tags: selectedTagsIds,
    };
    const { data } = await addQuestion({
      variables: questionData,
      refetchQueries: [{ query: ALL_QUESTIONS_QUERY }],
    });
    const { id } = data.createQuestion.question;

    history.push(`/questions/show/${id}`);
  };

  const setAnswer = (title, index) => {
    setAnswers(prev => {
      const data = [...prev];
      data[index] = title;
      return data;
    });
  };

  const isSubmitAllow = [...answers, title, correctAnswer].every(
    item => item !== null && item !== '',
  );

  return (
    <>
      <Heading size={700} className="heading">
        Add new question
      </Heading>
      <Pane>
        <TextInputField
          label="Question Title"
          required
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
        <TextInputField
          label="Image Url"
          required
          value={imageUrl}
          onChange={e => setImageUrl(e.target.value)}
        />
        <Multiselect tags={tags} onSelect={setSelectedTags} />
        <Pane aria-label="Radio Group Label 16" role="group">
          {answers.map((answer, index) => {
            const isCorrect = correctAnswer === index;
            const label = isCorrect ? 'correct variant' : 'incorrect variant';
            const correctClass = isCorrect ? 'correct' : '';

            return (
              <div className={`answer-variant ${correctClass}`} key={index}>
                <TextInputField
                  label={`Answer ${index + 1}`}
                  width={320}
                  required
                  value={answer}
                  onChange={e => setAnswer(e.target.value, index)}
                />
                <Radio
                  size={16}
                  marginLeft={15}
                  name="correct"
                  onChange={() => setCorrectAnswer(index)}
                  label={label}
                />
              </div>
            );
          })}
        </Pane>

        <Button
          type="submit"
          appearance="primary"
          onClick={handlerSubmit}
          disabled={!isSubmitAllow}
          isLoading={loading}>
          Submit!
        </Button>
      </Pane>
    </>
  );
}
