import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import client from './utils/apolloClient';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
// import { createBrowserHistory } from 'history';
// const history = createBrowserHistory();

ReactDOM.render(
  <Router>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Router>,
  document.getElementById('root'),
);
