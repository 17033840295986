import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import Query from '../Query';
import QuestionTable from './ui/table';
import {
  Button,
  Dialog,
  SearchInput,
  Checkbox,
  Pane,
  toaster,
  NumberedListIcon,
} from 'evergreen-ui';
import QuestionPagination from './ui/pagination';
import { useMutation, useQuery } from '@apollo/react-hooks';

import MultiselectTags from './ui/multiselectTags';

import QUESTION_DELETE from '../../queries/questions/delete';
import UPDATE_QUESTION_TAGS from '../../queries/questions/updateTags';
import ALL_QUERY from '../../queries/questions/all';
import USER_QUERY from '../../queries/user/user';

export default function ListQuestions() {
  const [selectedIds, setSelectedIds] = useState([]);
  const [userId, setUserId] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [filterTags, setFilterTags] = useState([]);
  const [tagsLoading, setTagsLoading] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const [onlyMyQuestionsChecked, setOnlyMyQuestionsChecked] = useState(false);

  const [paginationVariables, setPaginationVariables] = useState({});
  const [paginationVisible, setPaginationVisible] = useState(true);

  let { data, loading: questionsLoading } = useQuery(ALL_QUERY, {
    variables: { ...paginationVariables, searchQuery: searchStr, userId: onlyMyQuestionsChecked ? userId : undefined },
  });

  const [deleteQuestion, { loading }] = useMutation(QUESTION_DELETE, {
    update(cache, { data: { deleteQuestion } }) {
      const existingQuestions = cache.readQuery({ query: ALL_QUERY });
      const newQuestions = existingQuestions.questions.filter(
        q => q.id !== deleteQuestion.question.id,
      );
      cache.writeQuery({
        query: ALL_QUERY,
        data: { questions: newQuestions, userId: onlyMyQuestionsChecked ? userId : undefined },
      });
    },
  });
  // const [isShownDialog, setIsShowDialog] = useState(false);

  const [updateQuestion, { loading: updateQuestionLoading }] = useMutation(
    UPDATE_QUESTION_TAGS,
  );

  const { data: userData } = useQuery(USER_QUERY);

  useEffect(() => {
    if (userData?.me) {
      setUserId(userData.me.id);
    }
  }, [userData]);

  const handlerDelete = async id => {
    const { data } = await deleteQuestion({ variables: { id } });
    if (data) {
      toaster.success('Question successfully deleted');
    }
  };

  const handlerSearch = e => {
    const str = e.target.value.trim();
    setPaginationVisible(!str.length);
    setPaginationVariables({});
    setSearchStr(str);
  };

  const onlyMineCheckboxHandler = e => {
    const checked = !onlyMyQuestionsChecked;
    setOnlyMyQuestionsChecked(checked);
    setPaginationVisible(true);
    setPaginationVariables({});
    setSearchStr(searchStr);
  }

  const addSelectedTags = async () => {
    const selectedTagIds = selectedTags.map(t => t.id);
    setTagsLoading(true);
    for (const id of selectedIds) {
      const questionTags = data.questions.find(q => q.id === id).tags;
      const questionTagIds = questionTags.map(t => t.id);

      const newTags = new Set([...selectedTagIds, ...questionTagIds]);
      const questionData = { id, tags: [...newTags] };

      await updateQuestion({
        variables: questionData,
      });
    }
    setTagsLoading(false);
  };

  // console.log(data?.questions);

  const removeSelectedTags = async () => {
    const selectedTagIds = selectedTags.map(t => t.id);
    setTagsLoading(true);
    for (const id of selectedIds) {
      const questionTags = data.questions.find(q => q.id === id).tags;
      const questionTagIds = questionTags.map(t => t.id);

      const newTags = questionTagIds.filter(id => !selectedTagIds.includes(id));

      await updateQuestion({
        variables: { id, tags: newTags },
      });
    }
    setTagsLoading(false);
  };

  const showTagsButtons = selectedTags.length > 0 && selectedIds.length > 0;

  return (
    <>
      <Pane marginTop={20}>
        <Button is={Link} to="/questions/add">
          Add new question
        </Button>
      </Pane>

      <Pane marginTop={20}>
        <MultiselectTags onSelect={setSelectedTags} />
        {showTagsButtons && (
          <>
            <Button
              appearance="primary"
              intent="success"
              marginLeft={20}
              isLoading={tagsLoading}
              onClick={addSelectedTags}>
              Add selected tags
            </Button>
            <Button
              appearance="primary"
              intent="danger"
              marginLeft={20}
              isLoading={tagsLoading}
              onClick={removeSelectedTags}>
              Remove selected tags
            </Button>
          </>
        )}
      </Pane>

      <Pane marginTop={20} className="column-space-between">
        <SearchInput
          placeholder="Search by title..."
          onChange={handlerSearch}
          value={searchStr}
        />
        
        <Checkbox label="Only mine" checked={onlyMyQuestionsChecked} onChange={onlyMineCheckboxHandler}/>

        {/* <MultiselectTags onSelect={setFilterTags} title={'Filter Tags'} /> */}

        {paginationVisible && (
          <QuestionPagination setVariables={setPaginationVariables} />
        )}
      </Pane>

      {data?.questions && (
        <QuestionTable
          userId={userId}
          questions={data.questions}
          selected={selectedIds}
          onDelete={handlerDelete}
          onSelect={setSelectedIds}
          // onSearch={handlerSearch}
          loading={questionsLoading}
        />
      )}

      {/* <Dialog
        isShown={isShownDialog}
        title="Danger intent"
        intent="danger"
        onConfirm={() => setState({ isLoading: true })}
        onCloseComplete={() => setIsShowDialog(false)}
        confirmLabel="Delete Something">
        Dialog content
      </Dialog> */}
    </>
  );
}
