import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import Multiselect from './multiselect';
import GET_TAGS from '../../../queries/tags/get';

export default function MultiselectTags({ onSelect, title = 'Edit Tags...' }) {
  const [tags, setTags] = useState([]);

  const { data: tagsData } = useQuery(GET_TAGS);

  useEffect(() => {
    if (tagsData) {
      setTags(tagsData.questionTags);
    }
  }, [tagsData]);

  const selectHandler = selected => {
    const selectedTags = selected.map(selectedTitle =>
      tags.find(item => item.title === selectedTitle),
    );

    onSelect(selectedTags);
  };

  return <Multiselect tags={tags} onSelect={selectHandler} title={title} />;
}
