import gql from 'graphql-tag';

const ALL_QUERY = gql`
  query AllQuestions($start: Int = 1, $limit: Int, $searchQuery: String, $userId: String, $tagIds: [String]) {
    questions(
      start: $start
      limit: $limit
      sort: "updatedAt:desc"
      where: { questionTitle_contains: $searchQuery, authorId: $userId, tags: $tagIds }
    ) {
      id
      questionTitle
      authorId
      complexity_score
      isBlitz
      complexity_rating
      question_quality
      tags {
        id
        title
      }
    }
  }
`;

export default ALL_QUERY;
