import gql from 'graphql-tag';

const QUESTION_QUERY = gql`
  query Question($id: ID!) {
    question(id: $id) {
      id
      questionTitle
      imageUrl
      answers
      correctAnswer
      isBlitz
      tags {
        id
        title
      }
    }
  }
`;

export default QUESTION_QUERY;
