import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import USER_QUERY from '../../queries/user/user';
import { Spinner } from 'evergreen-ui';
import client from '../../utils/apolloClient';

export default function AppNavbar() {
  const { data, loading } = useQuery(USER_QUERY);
  const roleType = data?.me.role.type;

  localStorage.setItem('role', roleType);

  const handlerLogout = () => {
    client.cache.reset();
    localStorage.removeItem('token');
    localStorage.removeItem('role', roleType);
    window.location.href = '/';
  };

  const auth = (
    <>
      <Nav.Link as={Link} to="/profile">
        Profile
      </Nav.Link>
      <Nav.Link onClick={handlerLogout}>Sing Out</Nav.Link>
    </>
  );
  const notAuth = (
    <Nav.Link as={Link} to="/sign-in">
      Sing In
    </Nav.Link>
  );

  const questionmoderator = (
    <>
      <Nav.Link as={Link} to="/questions/add">
        Add question
      </Nav.Link>
      <Nav.Link as={Link} to="/questions">
        List questions
      </Nav.Link>
      <Nav.Link as={Link} to="/questions/statistics">
        Statistics
      </Nav.Link>
    </>
  );
  const questioncontributor = (
    <>
      <Nav.Link as={Link} to="/evaluate">
        Evaluate
      </Nav.Link>
      <Nav.Link as={Link} to="/evaluate/statistics">
        My statistics
      </Nav.Link>
    </>
  );

  const questionauthor = (
    <>
      <Nav.Link as={Link} to="/questions/add">
        Add question
      </Nav.Link>
      <Nav.Link as={Link} to="/questions">
        List questions
      </Nav.Link>
      <Nav.Link as={Link} to="/evaluate">
        Evaluate
      </Nav.Link>
      <Nav.Link as={Link} to="/questions/statistics">
        Statistics
      </Nav.Link>            
    </>
  );  

  const menu = {
    questionmoderator,
    questioncontributor,
    questionauthor
  };

  const menuNavs = menu[roleType];
  const profileMenu = roleType ? auth : notAuth;

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Brand as={Link} to="/">
          <img
            alt=""
            src="/assets/images/logo.png"
            height="30"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">{loading ? <Spinner /> : menuNavs}</Nav>
          <Nav>{loading ? <Spinner /> : profileMenu}</Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
