import React from 'react';
import Query from '../Query';
import QUESTIONS_COUNT from '../../queries/questions/count';
import QUESTIONS_RANGE from '../../queries/questions/range';

function randomInteger(min, max) {
  let rand = min - 0.5 + Math.random() * (max - min + 1);
  return Math.round(rand);
}

export default function RandomQuestion({ children }) {
  return (
    <>
      <Query query={QUESTIONS_COUNT}>
        {({ data: { questionsCount } }) => {
          const start = randomInteger(0, questionsCount - 1);
          const variables = { start, limit: 1 };
          return (
            <Query query={QUESTIONS_RANGE} variables={variables}>
              {({ data: { questions } }) => {
                const question = questions[0];
                return children({ question });
              }}
            </Query>
          );
        }}
      </Query>
    </>
  );
}
