import gql from 'graphql-tag';

const UPDATE_QUESTION = gql`
  mutation UpdateQuestion(
    $id: ID!
    $title: String!
    $imageUrl: String
    $answers: JSON!
    $correctAnswer: Int!
    $tags: [ID]!
  ) {
    updateQuestion(
      input: {
        where: { id: $id }
        data: {
          questionTitle: $title
          imageUrl: $imageUrl
          answers: $answers
          correctAnswer: $correctAnswer
          tags: $tags
        }
      }
    ) {
      question {
        id
        questionTitle
        imageUrl
        answers
        correctAnswer
        tags {
          id
          title
        }
      }
    }
  }
`;

export default UPDATE_QUESTION;
