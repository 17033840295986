import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Spinner } from 'evergreen-ui';

const Query = ({ children, query, id = null, variables = {} }) => {
  const { data, loading, error } = useQuery(query, {
    variables: { id, ...variables },
  });
  if (loading) return <Spinner marginX="auto" marginY={120} />;
  if (error) return <p>Error: {JSON.stringify(error)}</p>;
  return children({ data });
};

export default Query;
