import React from 'react';
import { match, wrap, filter } from 'fuzzaldrin-plus';
import { Link } from 'react-router-dom';
import {
  Table,
  Popover,
  Position,
  Menu,
  Avatar,
  Text,
  Icon,
  Button,
  Checkbox,
  IconButton,
  Spinner,
  Pane,
  TextDropdownButton,
} from 'evergreen-ui';

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

const Order = {
  NONE: 'NONE',
  ASC: 'ASC',
  DESC: 'DESC',
};

export default class AdvancedTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchQuery: '',
      orderedColumn: 1,
      ordering: Order.NONE,
      column2Show: 'id',
    };
  }

  sort = questions => {
    const { ordering, orderedColumn } = this.state;
    // Return if there's no ordering.
    if (ordering === Order.NONE) return questions;

    // Get the property to sort each question on.
    // By default use the `name` property.
    let propKey = 'name';
    // The second column is dynamic.
    if (orderedColumn === 2) propKey = this.state.column2Show;
    // The third column is fixed to the `ltv` property.
    if (orderedColumn === 3) propKey = 'ltv';

    return questions.sort((a, b) => {
      let aValue = a[propKey];
      let bValue = b[propKey];

      // Parse money as a number.
      const isMoney = aValue.indexOf('$') === 0;

      if (isMoney) {
        aValue = Number(aValue.slice(1));
        bValue = Number(bValue.slice(1));
      }

      // Support string comparison
      const sortTable = { true: 1, false: -1 };

      // Order ascending (Order.ASC)
      if (this.state.ordering === Order.ASC) {
        return aValue === bValue ? 0 : sortTable[aValue > bValue];
      }

      // Order descending (Order.DESC)
      return bValue === aValue ? 0 : sortTable[bValue > aValue];
    });
  };

  // Filter the questions based on the questionTitle property.
  filter = questions => {
    const searchQuery = this.state.searchQuery.trim();

    // If the searchQuery is empty, return the questions as is.
    if (searchQuery.length === 0) return questions;

    const filteredQuestions = questions.filter(question => {
      // Use the filter from fuzzaldrin-plus to filter by questionTitle.
      const result = filter([question.questionTitle], searchQuery);
      return result.length === 1;
    });

    return filteredQuestions;
    // return filteredQuestions.map(question => {
    //   // console.log(match(question.questionTitle, searchQuery)); // imput 1649, outup [27, 28, 29, 30]
    //   const wrappedQuestionTitle = wrap(question.questionTitle, searchQuery);
    //   return { ...question, questionTitle: wrappedQuestionTitle };
    // });
  };

  getIconForOrder = order => {
    switch (order) {
      case Order.ASC:
        return 'arrow-up';
      case Order.DESC:
        return 'arrow-down';
      default:
        return 'caret-down';
    }
  };

  handleFilterChange = value => {
    this.setState({ searchQuery: value });
    // this.props.onSearch(value);
  };

  renderValueTableHeaderCell = () => {
    return (
      <Table.HeaderCell>
        <Popover
          position={Position.BOTTOM_LEFT}
          content={({ close }) => (
            <Menu>
              {/* <Menu.OptionsGroup
                title="Order"
                options={[
                  { label: 'Ascending', value: Order.ASC },
                  { label: 'Descending', value: Order.DESC },
                ]}
                selected={
                  this.state.orderedColumn === 2 ? this.state.ordering : null
                }
                onChange={value => {
                  this.setState({
                    orderedColumn: 2,
                    ordering: value,
                  });
                  // Close the popover when you select a value.
                  close();
                }}
              /> */}

              {/* <Menu.Divider /> */}

              <Menu.OptionsGroup
                title="Show"
                options={[
                  { label: 'Complexity score', value: 'complexity_score' },
                  { label: 'isBlitz', value: 'isBlitz' },
                  { label: 'Complexity rating', value: 'complexity_rating' },
                  { label: 'Question quality', value: 'question_quality' },
                  { label: 'Id', value: 'id' },
                ]}
                selected={this.state.column2Show}
                onChange={value => {
                  this.setState({
                    column2Show: value,
                  });
                  // Close the popover when you select a value.
                  close();
                }}
              />
            </Menu>
          )}>
          <TextDropdownButton
            icon={
              this.state.orderedColumn === 2
                ? this.getIconForOrder(this.state.ordering)
                : 'caret-down'
            }>
            {capitalize(this.state.column2Show)}
          </TextDropdownButton>
        </Popover>
      </Table.HeaderCell>
    );
  };

  renderLTVTableHeaderCell = () => {
    return (
      <Table.TextHeaderCell>
        <Button appearance="minimal">Tags</Button>
      </Table.TextHeaderCell>
    );
  };

  renderRowMenu = () => {
    return (
      <>
        <Menu>
          <Menu.Group>
            <Menu.Item>Share...</Menu.Item>
            <Menu.Item>Move...</Menu.Item>
            <Menu.Item secondaryText="⌘R">Rename...</Menu.Item>
          </Menu.Group>
          <Menu.Divider />
          <Menu.Group>
            <Menu.Item intent="danger">Delete...</Menu.Item>
          </Menu.Group>
        </Menu>
      </>
    );
  };

  renderRow = ({ question }) => {
    const roleType = localStorage.getItem('role');
    const isQuestionAuthor = roleType == 'questionauthor' ||  question.authorId === this.props.userId;

    const onSelect = e => {
      const { checked } = e.target;
      if (checked) {
        this.props.onSelect(prev => [...prev, question.id]);
      } else {
        this.props.onSelect(prev => prev.filter(id => id !== question.id));
      }
    };

    const tags = question.tags.map(tag => tag.title).join(' ');

    return (
      <Table.Row key={question.id}>
        <Table.Cell display="flex" alignItems="center" width={50}>
          {/* <Avatar name={question.questionTitle} /> */}
          <Checkbox
            label={question.questionTitle}
            size={50}
            checked={this.props.selected.includes(question.id)}
            disabled={!isQuestionAuthor}
            fontWeight={500}
            onChange={onSelect}
          />
          {/* <Text marginLeft={8} size={300} fontWeight={500}>
            {question.questionTitle}
          </Text> */}
        </Table.Cell>
        <Table.TextCell>{question[this.state.column2Show]}</Table.TextCell>
        <Table.TextCell>{tags}</Table.TextCell>
        <Table.Cell width={150} flex="none">
          <IconButton
            height={60}
            // appearance="minimal"
            intent="success"
            icon="eye-open"
            is={Link}
            disabled={this.props.loading}
            to={`questions/show/${question.id}`}
          />
          {isQuestionAuthor && (
            <>
              <IconButton
                icon="edit"
                height={60}
                intent="warning"
                is={Link}
                disabled={this.props.loading}
                to={`questions/edit/${question.id}`}
              />
              <IconButton
                icon="delete"
                height={60}
                onClick={() => this.props.onDelete(question.id)}
                intent="danger"
                disabled={this.props.loading}
              />
            </>
          )}

          {/* <Popover
            content={this.renderRowMenu}
            position={Position.BOTTOM_RIGHT}>
            <IconButton icon="more" height={24} appearance="minimal" />
          </Popover> */}
        </Table.Cell>
      </Table.Row>
    );
  };

  render() {
    const items = this.filter(this.sort(this.props.questions));
    // const items = this.props.questions;
    const spinner = (
      <Pane
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={400}>
        <Spinner />
      </Pane>
    );
    return (
      <Table border marginTop={20}>
        <Table.Head>
          <Table.SearchHeaderCell
            onChange={this.handleFilterChange}
            value={this.state.searchQuery}
            placeholder="Search by title..."
          />
          {this.renderValueTableHeaderCell()}
          {this.renderLTVTableHeaderCell()}
          <Table.HeaderCell width={48} flex="none" />
        </Table.Head>
        <Table.VirtualBody height={640}>
          {this.props.loading
            ? spinner
            : items.map(item => this.renderRow({ question: item }))}
        </Table.VirtualBody>
      </Table>
    );
  }
}
