import gql from 'graphql-tag';

const SIGN_IN_MUTATION = gql`
  mutation login($email: String!, $password: String!) {
    login(input: { identifier: $email, password: $password }) {
      jwt
      user {
        username
      }
    }
  }
`;

export default SIGN_IN_MUTATION;
