import React from 'react';
import { Pane, TextInputField, Button, Radio } from 'evergreen-ui';
import Multiselect from './multiselect';

export default function ({
  answers,
  correctAnswer,
  handlerSubmit,
  title,
  setTitle,
  setAnswer,
  imageUrl, 
  setImageUrl,
  setCorrectAnswer,
  isSubmitAllow,
  loading,
  tags,
  setSelectedTags,
}) {
  return (
    <Pane>
      <TextInputField
        label="Question Title"
        required
        value={title}
        onChange={e => setTitle(e.target.value)}
      />
      <TextInputField
        label="Image Url"
        required
        value={imageUrl}
        onChange={e => setImageUrl(e.target.value)}
      />
      {imageUrl && imageUrl.length && (<div><img src={imageUrl} width="400" height="300"/></div>)}
      <Multiselect tags={tags} onSelect={setSelectedTags} />
      <Pane aria-label="Radio Group Label 16" role="group">
        {answers.map((answer, index) => {
          const isCorrect = correctAnswer === index;
          const label = isCorrect ? 'correct variant' : 'incorrect variant';
          const correctClass = isCorrect ? 'correct' : '';

          return (
            <div className={`answer-variant ${correctClass}`} key={index}>
              <TextInputField
                label={`Answer ${index + 1}`}
                width={320}
                required
                value={answer}
                onChange={e => setAnswer(e.target.value, index)}
              />
              <Radio
                size={16}
                marginLeft={15}
                checked={isCorrect}
                name="correct"
                onChange={() => setCorrectAnswer(index)}
                label={label}
              />
            </div>
          );
        })}
      </Pane>

      <Button
        type="submit"
        appearance="primary"
        onClick={handlerSubmit}
        disabled={!isSubmitAllow}
        isLoading={loading}>
        Submit!
      </Button>
    </Pane>
  );
}
