import React from 'react';
import { Spinner, Pane } from 'evergreen-ui';
import { useQuery } from '@apollo/react-hooks';

import EvaluateStatTable from '../evaluate/ui/statTable';
import QuestionsStatTable from '../questions/ui/statTable';
import USER_QUERY from '../../queries/user/user';

export default function AppHome() {
  const { data, loading } = useQuery(USER_QUERY);
  const roleType = data?.me.role.type;
  const userId = data?.me?.id;

  const tables = {
    questionmoderator: <QuestionsStatTable id={userId} />,
    questionauthor: <QuestionsStatTable id={userId} />,
    questioncontributor: <EvaluateStatTable id={userId} />,
  };

  if (loading) return <Spinner marginX="auto" marginY={120} />;

  return <Pane marginTop={20}>{userId && tables[roleType]}</Pane>;
}
