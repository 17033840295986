import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Evaluate from './Evaluate';
import Statistics from './Statistics';

export default function ListQuestions() {
  const { path } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route exact path={path} component={Evaluate} />
        <Route path={`${path}/statistics`} component={Statistics} />
      </Switch>
    </>
  );
}
