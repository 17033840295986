import gql from 'graphql-tag';

const EVALUATE_ADD = gql`
  mutation AddEvaluate(
    $questionId: String!
    $evaluatorId: String!
    $isCorrectAnswer: Boolean!
    $answerResponseTime: Float!
    $isBlitz: Boolean!
    $questionQuality: Float!
  ) {
    createEvaluateAnalytic(
      input: {
        data: {
          questionId: $questionId
          evaluatorId: $evaluatorId
          isCorrectAnswer: $isCorrectAnswer
          answerResponseTime: $answerResponseTime
          isBlitz: $isBlitz
          questionQuality: $questionQuality
        }
      }
    ) {
      evaluateAnalytic {
        id
      }
    }
  }
`;

export default EVALUATE_ADD;
