import React from 'react';
import { useParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { Pane, Heading, UnorderedList, ListItem, Badge } from 'evergreen-ui';
import Query from '../Query';

import QUESTION_QUERY from '../../queries/questions/question';

export default function () {
  const { id } = useParams();

  return (
    <Query query={QUESTION_QUERY} id={id}>
      {({ data: { question } }) => {
        return (
          <Pane>
            <Heading size={700} className="heading">
              {question.questionTitle}
            </Heading>
            {question.imageUrl && question.imageUrl.length && (<img src={question.imageUrl} />)}            
            <UnorderedList>
              {question.answers.map((answer, index) => {
                console.log(question, index);
                const isCorrect = question.correctAnswer === index;
                const iconType = isCorrect ? 'tick-circle' : 'ban-circle';
                const color = isCorrect ? 'success' : 'warning';
                return (
                  <ListItem icon={iconType} iconColor={color} key={index}>
                    {answer}
                  </ListItem>
                );
              })}
            </UnorderedList>
            <Pane>
              {question.tags.map(tag => (
                <Badge color="blue" key={tag.id} isSolid marginRight={8}>
                  {tag.title}
                </Badge>
              ))}
            </Pane>
          </Pane>
        );
      }}
    </Query>
  );
}
