import React from 'react';
import Rating from 'react-rating';
import { Pane, Heading, Icon } from 'evergreen-ui';

export default function RatingQuestion({ onRating }) {
  const emptyElement = <Icon icon="star-empty" color="muted" size={40} />;
  const fullElement = <Icon icon="star" color="#ffec10" size={40} />;

  return (
    <Pane className="rating-wrapper">
      <Heading size={700} className="heading">
        Please rate the quality of the question
      </Heading>
      <Rating
        onChange={onRating}
        emptySymbol={emptyElement}
        fullSymbol={fullElement}
        fractions={2}
      />
    </Pane>
  );
}
